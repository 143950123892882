// hikesData.js

export const hikes = [
  {
    id: 1,
    name: "Mount Daniel & Circle Lake",
    wildernessArea: "Alpine Lakes Wilderness",
    length: 20, // miles
    dogsAllowed: true,
    highlights: "Class 2 and 3 scramble",
    permit: "Self-permit",
    distanceFromSeattle: 110, // miles
    coverPhotos: ["/images/hikes/daniel3.jpg"],
    photos: ["/images/hikes/daniel4.jpg", "images/hikes/daniel3.jpg", "images/hikes/daniel2.jpg", "images/hikes/daniel1.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/C3Y3m85lJ3k?si=ukDIFoH3W_3lHwAk",
  },
  {
    id: 2,
    name: "Mount Baker",
    wildernessArea: "North Cascades",
    length: 14, // miles
    dogsAllowed: true,
    highlights: "Roped In Glacier Travel - expertise required",
    permit: false,
    distanceFromSeattle: 120, // miles
    coverPhotos: ["/images/hikes/baker3.jpg"],
    photos: ["/images/hikes/baker1.jpg", "images/hikes/baker2.jpg", "images/hikes/baker3.jpg", "images/hikes/baker4.jpg", "images/hikes/baker5.jpg", "images/hikes/baker6.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/tN_t_plahUs?si=WKmjcE1GonhS7FdY",
  },
  {
    id: 3,
    name: "Glacier Peak",
    wildernessArea: "Glacier Peak",
    length: 30, // miles
    dogsAllowed: true,
    highlights: "Roped In Glacier Travel or Class 3 Scrambling - expertise required",
    permit: "Self-Permit",
    distanceFromSeattle: 100, // miles
    coverPhotos: ["/images/glacier3.jpg"],
    photos: ["/images/hikes/glacierpeak1.jpg", "/images/hikes/glacierpeak2.jpg", "/images/hikes/glacierpeak3.jpg", "/images/hikes/glacierpeak4.jpg","/images/hikes/glacierpeak5.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/RL_lw9CEsFQ?si=nEf6xDJ2qmnxr2P8",
  },
  {
    id: 4,
    name: "Chelan Lakeshore Trail",
    wildernessArea: "Entiat Mountains",
    length: 20, // miles
    dogsAllowed: true,
    highlights: "Take a ferry to hike along this remote lakeshore",
    permit: "Ferry Reservation",
    distanceFromSeattle: 200, // miles
    coverPhotos: ["/images/hikes/chelanlakeshore1.jpg"],
    photos: ["/images/hikes/chelanlakeshore1.jpg","/images/hikes/chelanlakeshore3.jpg","/images/hikes/chelanlakeshore2.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/W2VVr-54P-U?si=8ScMIWgN9rXjQTzu",
  },
  {
    id: 5,
    name: "Golden Ears",
    wildernessArea: "Golden Ears",
    length: 20, // miles
    dogsAllowed: true,
    highlights: "Cross over into Canada for this delightful summit, and sleep on top of platforms above the lakes below",
    permit: "BC Parks Backcountry Permit",
    distanceFromSeattle: 180, // miles
    coverPhotos: ["/images/hikes/goldenears3.jpg"],
    photos: ["/images/hikes/goldenears1.jpg", "/images/hikes/goldenears2.jpg", "/images/hikes/goldenears3.jpg","/images/hikes/goldenears4.jpg", "/images/hikes/goldenears5.jpg","/images/hikes/goldenears6.jpg","/images/hikes/goldenears7.jpg","/images/hikes/goldenears8.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/kjVNye2uVUs?si=W57Ic-5rBwI5_GM8",
  },
  {
    id: 6,
    name: "Abernathy Peak",
    wildernessArea: "Methow/Sawtooth",
    length: 12, // miles
    dogsAllowed: true,
    highlights: "Scramble up this scree covered class three peak, and camp and the peaceful Scatter Lake with the mountain goats",
    permit: "None",
    distanceFromSeattle: 250, // miles
    coverPhotos: ["/images/hikes/abernathy1.jpg"],
    photos: ["/images/hikes/abernathy1.jpg", "/images/hikes/abernathy2.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/mDMWxQ8SSHc?si=f4N1i347DKnywDhP",
  },
  {
    id: 7,
    name: "Welcome Pass",
    wildernessArea: "North Cascades",
    length: 5, // miles
    dogsAllowed: true,
    highlights: "Scramble up this short but beautiful trail and spread out on High Divide, with Mount Baker right there.",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/welcomepass2.jpg"],
    photos: ["/images/hikes/welcomepass1.jpg", "/images/hikes/welcomepass2.jpg", "/images/hikes/welcomepass3.jpg", "/images/hikes/welcomepass4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/qYvBYYy50e8?si=x1fM3mUWXZBvX2zp",
  },
  {
    id: 8,
    name: "Wing Lake, Black Peak",
    wildernessArea: "North Cascades",
    length: 12, // miles
    dogsAllowed: true,
    highlights: "Scramble up this scree covered class three peak, and camp and the gorgeous Wing Lake among the Larches",
    permit: "None",
    distanceFromSeattle: 200, // miles
    coverPhotos: ["/images/hikes/winglake5.jpg"],
    photos: ["/images/hikes/winglake1.jpg", "/images/hikes/winglake2.jpg", "/images/hikes/winglake3.jpg", "/images/hikes/winglake4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/h0_UPXHUEXE?si=PW5EzfHlYowTZkLZ",
  },
  {
    id: 9,
    name: "Yellow Aster Butte",
    wildernessArea: "North Cascades",
    length: 10, // miles
    dogsAllowed: true,
    highlights: "Wonderful hike for all ages, camp among the tarns just below Yellow Aster Butte",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/yellowaster1.jpg"],
    photos: ["/images/hikes/yellowaster2.jpg", "/images/hikes/yellowaster3.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/qYvBYYy50e8?si=x1fM3mUWXZBvX2zp",
  },
  {
    id: 10,
    name: "Lake Edna",
    wildernessArea: "Central Cascades",
    length: 12, // miles
    dogsAllowed: true,
    highlights: "A less-traveled hike that climbs quickly and rewards with a serene alpine lake",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/edna1.jpg"],
    photos: ["/images/hikes/edna2.jpg", "/images/hikes/edna3.jpg"],
    youtubeVideo: "",
  },
  {
    id: 11,
    name: "Bean Creek Basin & Bean Peak",
    wildernessArea: "Central Cascades",
    length: 8, // miles
    dogsAllowed: true,
    highlights: "A great early-season hike while the rest of the state thaws out. Scramble up Bean Peak for views of the enchantments",
    permit: "None",
    distanceFromSeattle: 110, // miles
    coverPhotos: ["/images/hikes/beanpeak5.jpg"],
    photos: ["/images/hikes/beanpeak1.jpg", "/images/hikes/beanpeak3.jpg", "/images/hikes/beanpeak2.jpg", "/images/hikes/beanpeak4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/pO6FdPXZls8?si=VbuFjdJz2iWX8yGk",
  },
  {
    id: 12,
    name: "Eagle Lakes",
    wildernessArea: "Methow/Sawtooth",
    length: 13, // miles
    dogsAllowed: true,
    highlights: "Looking for a larch-march? Eagle Lakes is worth the long drive. Bring a fishing pole for peak fun!",
    permit: "None",
    distanceFromSeattle: 230, // miles
    coverPhotos: ["/images/hikes/eaglelakes2.jpg"],
    photos: ["/images/hikes/eaglelakes3.jpg", "/images/hikes/eaglelakes4.jpg", "/images/hikes/eaglelakes5.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/4Ew9Fie_Dks?si=DDsloIXUEM1W1aar",
  },
  {
    id: 16,
    name: "Baldy Peak",
    wildernessArea: "Olympics",
    length: 8, // miles
    dogsAllowed: true,
    highlights: "A less-traveled hike that climbs quickly and rewards with a open views over the Olympics",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/baldy1.jpg"],
    photos: ["/images/hikes/baldy2.jpg", "/images/hikes/baldy3.jpg", "/images/hikes/baldy4.jpg", "/images/hikes/baldy5.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/F8QmlN0mWPI?si=idDYQSi-oKmKgagI",
  },
  {
    id: 17,
    name: "Barclay Lake",
    wildernessArea: "Central Cascades",
    length: 5, // miles
    dogsAllowed: true,
    highlights: "A perfect family friendly day hike or first-time backpacking trip",
    permit: "None",
    distanceFromSeattle: 80, // miles
    coverPhotos: ["/images/hikes/barclay1.jpg"],
    photos: ["/images/hikes/barclay2.jpg", "/images/hikes/barclay3.jpg", "/images/hikes/barclay4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/giCEsdDNZj0?si=Eayb6DBGRU5DtTbQ",
  },
  {
    id: 13,
    name: "Columbia River Gorge",
    wildernessArea: "Southern Washington",
    length: 5, // miles
    dogsAllowed: true,
    highlights: "Looking for wildflowers and an early-season day hike? Check out the Columbia River Gorge!",
    permit: "None",
    distanceFromSeattle: 80, // miles
    coverPhotos: ["/images/hikes/columbia5.jpg"],
    photos: ["/images/hikes/columbia1.jpg", "/images/hikes/columbia3.jpg", "/images/hikes/columbia4.jpg", "/images/hikes/columbia5.jpg", "/images/hikes/columbia6.jpg", "/images/hikes/columbia7.jpg"],
    youtubeVideo: "",
  },
  {
    id: 14,
    name: "Cutthroat Pass",
    wildernessArea: "North Cascades",
    length: 12, // miles
    dogsAllowed: true,
    highlights: "Experience a day or two of North Cascades goodness on the PCT from Rainy Pass off Highway 20",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/cutthroat1.jpg"],
    photos: ["/images/hikes/cutthroat2.jpg", "/images/hikes/cutthroat3.jpg", "/images/hikes/cutthroat4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/7s5W8BCihMw?si=yFa784hlnf1BEXB8",
  },
  {
    id: 15,
    name: "The Enchantments",
    wildernessArea: "Alpine Lakes Wilderness",
    length: 20, // miles
    dogsAllowed: false,
    highlights: "Possibly Washington's most famous hike. Enter the lottery to obtain a permit, and you won't forget it!",
    permit: "None",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/enchantments1.jpg"],
    photos: ["/images/hikes/enchantments2.jpg", "/images/hikes/enchantments3.jpg", "/images/hikes/enchantments4.jpg", "/images/hikes/enchantments5.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/gUbGl3ILEJ0?si=1mH8-QRUdr7on5S5",
  },
  {
    id: 18,
    name: "Mount St. Helens",
    wildernessArea: "Mt. St. Helens",
    length: 10, // miles
    dogsAllowed: false,
    highlights: "This hike is a little easier than it was in 1979. Climb an active volcano and look down on the crater!",
    permit: "Required",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/helens1.jpg"],
    photos: ["/images/hikes/helens2.jpg", "/images/hikes/helens3.jpg", "/images/hikes/helens4.jpg", "/images/hikes/helens5.jpg", "/images/hikes/helens6.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/lSVY447Dgyk?si=k9YK5W9mBbcsAPYy",
  },
  {
    id: 19,
    name: "Old Snowy, Goat Lake",
    wildernessArea: "Goat Rocks Wilderness",
    length: 17, // miles
    dogsAllowed: true,
    highlights: "Goat Rocks is a must-visit. Unlike forested mountains in the North Cascades, experience expansive views right out of the car.",
    permit: "Self-Permit",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/goatrocks2.jpg"],
    photos: ["/images/hikes/goatrocks1.jpg", "/images/hikes/goatrocks3.jpg", "/images/hikes/goatrocks4.jpg", "/images/hikes/goatrocks5.jpg"],
    youtubeVideo: "",
  },
  {
    id: 20,
    name: "High Rock Lookout",
    wildernessArea: "South Cascades",
    length: 4, // miles
    dogsAllowed: true,
    highlights: "Looking for a quick after-work hike? This will get you right up in front of Mount Rainier and quickly",
    permit: "false",
    distanceFromSeattle: 100, // miles
    coverPhotos: ["/images/hikes/highrock1.jpg"],
    photos: ["/images/hikes/highrock2.jpg", "/images/hikes/highrock3.jpg", "/images/hikes/highrock4.jpg", "/images/hikes/highrock5.jpg"],
    youtubeVideo: "",
  },
  {
    id: 21,
    name: "Kelly Butte",
    wildernessArea: "Mt. Rainier Area",
    length: 4, // miles
    dogsAllowed: true,
    highlights: "Looking for a quick after-work hike? This will get you right up in front of Mount Rainier and quickly, the perfect date spot.",
    permit: "false",
    distanceFromSeattle: 100, // miles
    coverPhotos: ["/images/hikes/kelly1.jpg"],
    photos: ["/images/hikes/kelly2.jpg", "/images/hikes/kelly3.jpg", "/images/hikes/kelly4.jpg", "/images/hikes/kelly5.jpg"],
    youtubeVideo: "",
  },
  {
    id: 22,
    name: "Lyman Lake & Spider Gap",
    wildernessArea: "Entiat Mountains",
    length: 21, // miles
    dogsAllowed: true,
    highlights: "This hike just gets better and better! After leaving the car, you will feel miles from civilization. Be sure to take a dip in the glacier water",
    permit: "Self-permit",
    distanceFromSeattle: 150, // miles
    coverPhotos: ["/images/hikes/lyman1.jpg"],
    photos: ["/images/hikes/lyman2.jpg", "/images/hikes/lyman3.jpg", "/images/hikes/lyman4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/5VBIEcwxFn8?si=iW51SObAG3ShqXV1",
  },
  {
    id: 23,
    name: "Navaho Peak",
    wildernessArea: "Snoqualmie Region",
    length: 14, // miles
    dogsAllowed: true,
    highlights: "Rewarding summit views, camp up on the ridge for views of the Enchantments! ",
    permit: "None",
    distanceFromSeattle: 12, // miles
    coverPhotos: ["/images/hikes/navaho4.jpg"],
    photos: ["/images/hikes/navaho2.jpg", "/images/hikes/navaho3.jpg", "/images/hikes/navaho1.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/bet9iFIxsfA?si=WM2g8EWlT4D33n31",
  },
  {
    id: 24,
    name: "Raven Lake",
    wildernessArea: "Mountain Loop Highway",
    length: 8, // miles
    dogsAllowed: true,
    highlights: "Good luck finding this one!",
    permit: "None",
    distanceFromSeattle: 80, // miles
    coverPhotos: ["/images/hikes/raven1.jpg"],
    photos: ["/images/hikes/raven1.jpg", "/images/hikes/raven2.jpg"],
    youtubeVideo: "",
  },
  {
    id: 25,
    name: "Alice and Toxaway Lakes",
    wildernessArea: "Sawtooth Wilderness",
    length: 8, // miles
    dogsAllowed: true,
    highlights: "The Sawtooth Wilderness in Idaho is a must-visit. Unlike forested mountains in the North Cascades, experience expansive views right out of the car.",
    permit: "None",
    distanceFromSeattle: 400, // miles
    coverPhotos: ["/images/hikes/sawtooths1.jpg"],
    photos: ["/images/hikes/sawtooths2.jpg", "/images/hikes/sawtooths3.jpg", "/images/hikes/sawtooths4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/YBhdPGa0M80?si=JW32lNDDSmRY81bI",
  },
  {
    id: 26,
    name: "South Kaibab to Bright Angel",
    wildernessArea: "Grand Canyon National Park",
    length: 19, // miles
    dogsAllowed: false,
    highlights: "Experience the Grand Canyon from both the rim and the river in one day. This is a bucket list hike!",
    permit: "None",
    distanceFromSeattle: 1200, // miles
    coverPhotos: ["/images/hikes/grandcanyon1.jpg"],
    photos: ["/images/hikes/grandcanyon2.jpg", "/images/hikes/grandcanyon3.jpg", "/images/hikes/grandcanyon4.jpg", "/images/hikes/grandcanyon5.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/9YppPsLbJms?si=HHNlKnqkfmh9qZBl",
  },
  {
    id: 27,
    name: "Glacier Lake",
    wildernessArea: "Alpine Lakes Wilderness",
    length: 11, // miles
    dogsAllowed: true,
    highlights: "Scramble down to a beautiful lake with a waterfall flowing into it, and try the scramble up to Chickamin Peak",
    permit: "None",
    distanceFromSeattle: 70, // miles
    coverPhotos: ["/images/hikes/glacier10.jpg"],
    photos: ["/images/hikes/glacier20.jpg", "/images/hikes/glacier30.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/U0C6nXE8-zI?si=ox575iIu8yRYoGfk",
  },
  {
    id: 28,
    name: "Chiwawa Mountain",
    wildernessArea: "Glacier Peak Wilderness",
    length: 13, // miles
    dogsAllowed: true,
    highlights: "This is a very difficult and unmarked scramble, but the views are worth it!",
    permit: "Self-permit",
    distanceFromSeattle: 70, // miles
    coverPhotos: ["/images/hikes/chiwawa3.jpg"],
    photos: ["/images/hikes/chiwawa1.jpg", "/images/hikes/chiwawa2.jpg", "/images/hikes/chiwawa4.jpg", "/images/hikes/chiwawa3.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/xEGzUOrT_pA?si=LoZBDou1gxK7HuvW",
  },
  {
    id: 29,
    name: "Camp Muir",
    wildernessArea: "Mount Rainier National Park",
    length: 10, // miles
    dogsAllowed: true,
    highlights: "A long day hike that can be done year round, but be prepared for snow and ice",
    permit: "Self-permit",
    distanceFromSeattle: 100, // miles
    coverPhotos: ["/images/hikes/muir1.jpg"],
    photos: ["/images/hikes/muir2.jpg", "/images/hikes/muir3.jpg", "/images/hikes/muir4.jpg"],
    youtubeVideo: "https://www.youtube.com/embed/JUtlsWETtGA?si=S9ynoSm9fvhDuFKu",
  }
  // {
  //   id: ,
  //   name: "",
  //   wildernessArea: "",
  //   length: , // miles
  //   dogsAllowed: true,
  //   highlights: "",
  //   permit: "",
  //   distanceFromSeattle: , // miles
  //   coverPhotos: ["/images/hikes/"],
  //   photos: ["/images/hikes/glacierpeak1.jpg"],
  //   youtubeVideo: "",
  // },
];
